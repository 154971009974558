var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{staticClass:"pathModuls",attrs:{"items":_vm.pathModul,"divider":"-","normal":""}}),_c('v-container',{class:_vm.$vuetify.breakpoint.lgAndUp ? 'px-6' : 'px-1',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-select',{attrs:{"items":_vm.itemStation,"item-value":"name","item-text":"name","label":"Station","prepend-inner-icon":"mdi-store-marker","dense":"","solo":""},on:{"change":_vm.selectStation}})],1),_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prefix":"From :","prepend-inner-icon":"mdi-calendar-start","readonly":"","dense":"","solo":""},model:{value:(_vm.valueFromDateep),callback:function ($$v) {_vm.valueFromDateep=$$v},expression:"valueFromDateep"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar),callback:function ($$v) {_vm.modalCalendar=$$v},expression:"modalCalendar"}},[_c('v-date-picker',{on:{"change":_vm.selectFromDate,"input":function($event){_vm.modalCalendar = false}},model:{value:(_vm.valueFromDateep),callback:function ($$v) {_vm.valueFromDateep=$$v},expression:"valueFromDateep"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prefix":"To :","prepend-inner-icon":"mdi-calendar-end","readonly":"","dense":"","solo":""},model:{value:(_vm.valueToDateep),callback:function ($$v) {_vm.valueToDateep=$$v},expression:"valueToDateep"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar2),callback:function ($$v) {_vm.modalCalendar2=$$v},expression:"modalCalendar2"}},[_c('v-date-picker',{on:{"change":_vm.selectToDate,"input":function($event){_vm.modalCalendar2 = false}},model:{value:(_vm.valueToDateep),callback:function ($$v) {_vm.valueToDateep=$$v},expression:"valueToDateep"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"white--text button mr-2 mb-5",attrs:{"solo":"","dense":"","normal":"","disabled":_vm.valueStationep==null},on:{"click":_vm.getExceedanceParam}},[_vm._v(" GENERATE REPORT ")]),(this.$store.getters.user.idUserAccess !== 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"float":"right"},attrs:{"color":"excel","elevation":"2","disabled":_vm.expIcon == null},on:{"click":function($event){return _vm.exportExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)]}}],null,false,1696057025)},[_c('span',[_vm._v("Export to Excel")])]):_vm._e(),(this.$store.getters.user.idUserAccess !== 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5 mx-2",staticStyle:{"float":"right"},attrs:{"color":"csv","elevation":"2","disabled":_vm.expIcon == null},on:{"click":function($event){return _vm.exportCSV()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-file-delimited ")])],1)]}}],null,false,2634139483)},[_c('span',[_vm._v("Export to CSV")])]):_vm._e()],1)],1),_c('v-card',{staticClass:"pa-4",staticStyle:{"max-height":"calc(100vh - 230px)","overflow-y":"auto"}},[(_vm.queryValueep == true)?_c('v-subheader',{staticClass:"fontSubTitle-blue",class:{'subTitles':_vm.$vuetify.breakpoint.lgAndUp}},[_vm._v(" "+_vm._s(_vm.title_name)+" ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 headerDtSarawak headerDesktop mt-4",attrs:{"headers":_vm.thead_alertexceedanceByParameter,"items":_vm.tbody_alertexceedanceByParameter,"items-per-page":_vm.itemPerPage,"custom-sort":_vm.customSort,"loading":_vm.loadingTable,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.NOTES",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.NOTES,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "NOTES", $event)},"update:return-value":function($event){return _vm.$set(props.item, "NOTES", $event)},"save":_vm.saveExceedParam,"cancel":_vm.cancelExceedParam,"open":_vm.openExceedParam,"close":_vm.closeExceedParam},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Update Notes ")]),_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","autofocus":""},on:{"input":function($event){_vm.updateNotesExceedParam = {
                    newId: props.item.NEW_ID,
                    notes: $event,
                  }}},model:{value:(props.item.NOTES),callback:function ($$v) {_vm.$set(props.item, "NOTES", $$v)},expression:"props.item.NOTES"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(props.item.NOTES))])])]}}],null,true)}),_c('p',{staticStyle:{"font-size":"0.90em","text-align":"left"}},[_c('b',[_vm._v(" Note: Recommended Raw Water Quality Criteria (RRWQC) is a sets out the maximum acceptance levels of characteristics and constituents in raw water, which if exceeded will require special treatment. ")])]),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }